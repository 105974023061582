<template lang="pug">
.page
  el-card(header="编辑文章信息")
    el-form(label-width="6em")
      el-form-item(label="来源")
        el-radio-group(v-model="formData.source.type")
          el-radio-button(v-for="i in articleSourceTypeList" :label="i.value") {{ i.name }}
      el-form-item(label="链接" v-if="formData.source.type !== 'internal'")
        el-input(v-model="formData.source.link")
      el-form-item(label="标题")
        el-input(v-model="formData.title")
      el-form-item(label="简介")
        el-input(v-model="formData.desc")
      el-form-item(label="题图")
        jj-imagePick(v-model="formData.poster" size="poster")
      el-form-item(label="分类")
        el-select(v-model="formData.category")
          el-option(v-for="i in articleCategoryList" :label="i.name" :value="i._id")
      el-form-item(label="地区")
        el-select(v-model="formData.city")
          el-option(v-for="i in cityList" :label="i.name" :value="i._id")
      el-form-item(label="标签")
        jj-tagList(v-model="formData.tagList")
      el-form-item(label="关联职位")
        el-tag(v-for="(i,n) in formData.jobList" style="margin-right: 5px;" closable @close="formData.jobList.splice(n,1)") {{ i?.name }}
        el-button(size="mini" type="success" icon="el-icon-plus" @click="isShowJobSelect = true")
      tempalte(v-if="formData.source.type === 'internal'")
        el-form-item(label="内容")
          com-editor(v-model="formData.content" type="full")
      el-form-item()
        el-button(type="primary" @click="handleSubmit") 保存

  comJobSelect(v-model:isShow="isShowJobSelect" @success="onJobSelect")
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue'
import comEditor from '@/component/editor/index.vue'
import comJobSelect from '@/component/select/job.vue'
import { articleSourceTypeList, formatArticleSourceType } from '@/dict/article'
import { articleCategoryList, getArticleCategoryList, getCityList, cityList } from '@/provider/global'
import { pickImageList } from '@/provider/upload'

const { router, route, fetch, message } = inject('global')

const formData = ref({
  _id: '',
  source: {
    type: 'wechat',
    link: ''
  },
  title: '',
  desc: '',
  poster: '',
  content: '',
  category: '',
  city: '',
  tagList: [],
  jobList: []
})

const isShowJobSelect = ref(false)

function onJobSelect(val) {
  const existDoc = formData.value.jobList.find(i => i._id === val._id)
  if (!existDoc) {
    formData.value.jobList.push(val)
  }
}

function handlePickPoster() {
  pickImageList({ count: 1, type: 'article' }).then(res => {
    if (res?.length) {
      formData.value.poster = res[0]
    }
  })
}

function handleSubmit() {
  if (!formData.value.title) {
    message.warning('标题不能为空')
  } else if (!formData.value.category) {
    message.warning('分类不能为空')
  } else if (formData.value.source.type === 'internal' && !formData.value.content) {
    message.warning('内容不能为空')
  } else {
    const { _id, ...payload } = formData.value
    return Promise.resolve()
      .then(() => {
        if (_id) {
          return fetch.put(`/article/${_id}`, {
            ...payload
          })
        } else {
          return fetch.post(`/article`, {
            ...payload
          })
        }
      })
      .then(res => {
        message.warning('保存成功')
        router.push('/article')
      })
  }
}

function initData() {
  if (route.params.id === 'new') {
    formData.value = {
      _id: '',
      source: {
        type: 'wechat',
        link: ''
      },
      title: '',
      desc: '',
      content: '',
      category: '',
      city: '',
      tagList: [],
      jobList: []
    }
  } else {
    fetch.get(`/article/${route.params.id}`).then(res => {
      formData.value = res
    })
  }
}

onMounted(() => {
  getArticleCategoryList()
  getCityList()
  initData()
})

watch(
  () => route?.params?.id,
  val => {
    initData()
  }
)
</script>

<style lang="less" scoped>
.addItem {
  .flexCenter();
  .border();
  width: 50px;
  height: 50px;
}
</style>
