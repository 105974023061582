import { ref, computed } from 'vue'

export const articleSourceTypeList = ref([
  { name: '内部', value: 'internal' },
  { name: '微信公众号', value: 'wechat' }
])

export const articleSourceTypeListWithAll = computed(() => {
  return [{ name: '全部', value: '' }, ...articleSourceTypeList.value]
})

export function formatArticleSourceType(val) {
  if (!val) return '全部'
  const item = articleSourceTypeList.value.find(i => i.value === val)
  if (item) return item.name
  return val
}

export const articleSortList = ref([
  { label: '创建时间-倒序', value: '-createTime' },
  { label: '创建时间-正序', value: 'createTime' },
  { label: '更新时间-倒序', value: '-updateTime' },
  { label: '更新时间-正序', value: 'updateTime' },
  { label: '置顶-倒序', value: '-digScore' },
  { label: '置顶-正序', value: 'digScore' },
  { label: '阅读量-倒序', value: '-readCount' },
  { label: '阅读量-正序', value: 'readCount' },
  { label: '收藏量-倒序', value: '-collectCount' },
  { label: '收藏量-正序', value: 'collectCount' },
  { label: '分享量-倒序', value: '-shareCount' },
  { label: '分享量-正序', value: 'shareCount' }
])

export const articleTagList = ['编辑推荐', '双休', '五险一金', '包吃住', '高薪']
